<template>
  <tj-dialog
    v-model="visible"
    title="工程师信息"
    @close="onClose"
  >
    <el-form v-if="form.user" label-width="100px">
      <el-form-item>
        <el-avatar :src="form.user.avatar"></el-avatar>
      </el-form-item>
      <el-form-item label="姓名：">{{ form.user.name }}</el-form-item>
      <el-form-item label="性别：">{{ sexMap[form.user.sex] }}</el-form-item>
      <el-form-item label="年龄：">{{ form.user.age }}</el-form-item>
      <el-form-item label="手机号：">
        <el-link
          v-if="form.user.mobile"
          type="primary"
          :icon="Phone"
          :underline="false"
          :href="`tel:${form.user.mobile}`"
        >{{ form.user.mobile }}</el-link>
      </el-form-item>
      <el-form-item label="证件号码：">{{ form.user.id_card }}</el-form-item>
      <el-form-item label="地址：">
        {{ form.user.province }}
        -
        {{ form.user.city }}
        -
        {{ form.user.area }}
        -
        {{ form.user.address }}
      </el-form-item>
      <el-form-item v-if="form.category && form.category.length" label="分类：">
        <el-space>
          <el-tag
            v-for="(item, index) in form.category"
            :key="index"
          >{{ item.category_name }} | {{ item.brand_name }}</el-tag>
        </el-space>
      </el-form-item>
      <el-form-item
        v-if="form.certificate && form.certificate.length"
        label="证书："
      >
        <el-button
          v-if="hideCertificate"
          type="primary"
          @click="hideCertificate = false"
        >查看证书</el-button>
        <template v-else>
          <el-image
            v-for="item in form.certificate"
            :key="item.id"
            :src="item.url"
          ></el-image>
        </template>
      </el-form-item>
      <el-form-item label="状态：">
        <el-tag v-if="form.user.status === -1" type="info">未认证</el-tag>
        <el-tag v-else-if="form.user.status === 0" type="warning">待审核</el-tag>
        <el-tag v-else-if="form.user.status === 1" type="success">审核通过</el-tag>
        <el-tag v-else-if="form.user.status === 2" type="danger">审核失败</el-tag>
      </el-form-item>
      <el-form-item v-if="form.user.status === 0" label="审核：">
        <el-button type="primary" @click="examine(true)">通过</el-button>
        <el-button type="danger" @click="examine(false)">拒绝</el-button>
      </el-form-item>
      <template v-if="form.user.status === 1">
        <el-form-item label="职位：">
          <el-space>
            <span>{{ positionText }}</span>
            <el-button
              type="text"
              :icon="Edit"
              @click="changePosition"
            >
              <template v-if="form.position">修改</template>
              <template v-else>设置</template>
            </el-button>
          </el-space>
        </el-form-item>
        <el-form-item label="分佣比例：">
          <el-space>
            <span><strong>上门费：</strong>{{ getRatio(form.user.door_ratio) }}</span>
            <span><strong>服务费：</strong>{{ getRatio(form.user.service_ratio) }}</span>
            <span><strong>配件费：</strong>{{ getRatio(form.user.part_ratio) }}</span>
            <el-button
              type="text"
              :icon="Edit"
              @click="changeRatio"
            >修改</el-button>
          </el-space>
        </el-form-item>
      </template>
    </el-form>
  </tj-dialog>
  <RatioSetting ref="ratioSetting" @submit="getDetail"></RatioSetting>
  <Position ref="position" @submit="onPositionChange"></Position>
</template>

<script setup>
import { ref, computed } from 'vue';
import { ElMessageBox } from 'element-plus';
import { Phone, Edit } from '@element-plus/icons-vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';
import sexMap from '../sexMap';
import RatioSetting from './RatioSetting.vue';
import Position from './Position.vue';

const emit = defineEmits(['submit']);

const defaultUserInfo = {
  user: null,
  category: [],
  certificate: [],
  position: null,
};

const form = ref({ ...defaultUserInfo });

let userId = 0;

const hideCertificate = ref(true);

function getDetail() {
  request.get(`/admin/engineer/user/${userId}`).then((data) => {
    form.value = data;
  });
}

const positionList = ref([]);
function getPositionList() {
  request.get('/admin/engineer/position').then((data) => {
    positionList.value = data;
  });
}

const positionText = computed(() => {
  if (form.value.position) {
    return form.value.position.name;
  }
  return '未设置';
});

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  userId = id;
  getDetail();
  getPositionList();
});
function onClose() {
  form.value = { ...defaultUserInfo };
  hideCertificate.value = true;
}

function submit(params) {
  request.post(`/admin/engineer/examine/${userId}`, params).then(() => {
    emit('submit');
    close();
  });
}

function examine(approved) {
  if (approved) {
    submit({ status: 1 });
  } else {
    ElMessageBox.prompt(null, '拒绝原因：', {
      confirmButtonText: '拒绝',
      cancelButtonText: '取消',
    }).then(({ value }) => {
      submit({ status: 2, examine_fail_reason: value });
    });
  }
}

function getRatio(ratio) {
  if (ratio) {
    return `${ratio * 100}%`;
  }
  return '未设置';
}

const ratioSetting = ref();
function changeRatio() {
  ratioSetting.value.open(form.value.user);
}

const position = ref();
function changePosition() {
  position.value.open(form.value.position ? form.value.position.id : undefined);
}
function onPositionChange(positionId) {
  request.post(`/admin/engineer/setPosition/${userId}`, {
    position_id: positionId,
  }).then(() => {
    getDetail();
  });
}

defineExpose({
  open,
});
</script>
